<template>
  <Card>
    <template #header> Devir Bilgileri </template>
    <div class="row">
      <div class="col-md-6 col-12">
        <div class="form-group">
          <label for="">Operasyon</label>
          <select name="" id="" class="form-control">
            <option option value="" selected>Seçiniz</option>
            <option value="">Asuman Kocabaş</option>
          </select>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-group">
          <label for="">Devir Tarafımızdan mı Yapılacak ?</label>
          <select name="" id="" class="form-control">
            <option value="" selected>Seçiniz</option>
            <option value="">Hayır</option>
            <option value="">Evet</option>
          </select>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-group">
          <label for="">Gayrimenkul üzerinden ipotek kaydı</label>
          <select name="" id="" class="form-control">
            <option value="" selected>Seçiniz</option>
            <option value="">Hayır</option>
            <option value="">Evet</option>
          </select>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-group">
          <label for="">Web tapu yetkisi var mı?</label>
          <select name="" id="" class="form-control">
            <option value="" selected>Seçiniz</option>
            <option value="">Hayır</option>
            <option value="">Evet</option>
          </select>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-group">
          <label for="">Tapu işlemi farklı bir müdürlükte mi yapılacak?</label>
          <select name="" id="" class="form-control">
            <option value="" selected>Seçiniz</option>
            <option value="">Hayır</option>
            <option value="">Evet</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-12">
        <div class="form-group">
          <label for="">Randevu alınan tarih</label>
          <input type="date" class="form-control" />
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-group">
          <label for="">Beyanı var mı ?</label>
          <select name="" id="" class="form-control">
            <option value="" selected>Seçiniz</option>
            <option value="">Hayır</option>
            <option value="">Evet</option>
          </select>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-group">
          <label for="">Borç miktarı</label>
          <input type="text" class="form-control" />
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-group">
          <label for="">Vekalet Alındı mı?</label>
          <select name="" id="" class="form-control">
            <option value="" selected>Seçiniz</option>
            <option value="">Hayır</option>
            <option value="">Evet</option>
          </select>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-group">
          <label for="">Rayiç Bedel Tutarı</label>
          <input type="text" class="form-control" />
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-group">
          <label for="">Portföy Yetki Durumu</label>
          <input type="text" class="form-control" />
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-group">
          <label for="">Satış Bedeli</label>
          <input type="text" class="form-control" />
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-group">
          <label for="">Hizmet Bedeli Var mı?</label>
          <select name="" id="" class="form-control">
            <option value="" selected>Seçiniz</option>
            <option value="">Hayır</option>
            <option value="">Evet</option>
          </select>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-group">
          <label for="">Emlak Vergisi Terkini Yapıldı mı?</label>
          <select name="" id="" class="form-control">
            <option value="" selected>Seçiniz</option>
            <option value="">Hayır</option>
            <option value="">Evet</option>
          </select>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="">Tarafımızdan Yapılan Ödemeler</label>
          <input type="text" class="form-control" />
        </div>
      </div>
    </div>
    <div class="row flex-column">
      <div
        class="w-100 pb-4 d-flex justify-content-between"
        style="border-bottom: 1px solid #f5f5f5"
      >
        <h4>Devir Bilgileri</h4>
        <b-button variant="light" @click="modalOpen = true">Düzenle</b-button>
      </div>
      <div class="d-flex mt-4">
        <div class="mr-4">
          <p>Ünvanı</p>
          <p>MUHİTTİN ORAK - (533) 714-03-40</p>
        </div>
        <div class="mr-4">
          <p>Devir</p>
          <p>100</p>
        </div>
        <div class="mr-4">
          <p>Kullanıcı</p>
          <p>ASUMAN KOCABAŞ</p>
        </div>
      </div>
    </div>
    <b-modal v-model="modalOpen" id="note-modal">
      <template #modal-header>
        <h4><i class="flaticon2-plus mr-4"></i>İhale Süresini Değiştir</h4>
      </template>

      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="type">Sonra ki Durum</label>
            <select name="" id="" class="form-control">
              <option value="" selected>Seçiniz</option>
              <option value="">Devir</option>
              <option value="">Satış</option>
            </select>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label for="type">Operasyon </label>
            <select name="" id="" class="form-control mt-6">
              <option value="" selected>Seçiniz</option>
              <option value="">ASUMAN KOCABAŞ</option>
            </select>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label for="type">Devri Bizim Tarafımızdan mı Yapıldı?</label>
            <select name="" id="" class="form-control">
              <option value="" selected>Seçiniz</option>
              <option value="">Hayır</option>
              <option value="">Evet</option>
            </select>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-5 col-12">
          <div class="form-group">
            <label for="">Hisse Sahibi</label>
            <select name="" id="" class="form-control">
              <option value="">ASUMAN KOCABAŞ</option>
            </select>
          </div>
        </div>
        <div class="col-md-3 col-12">
          <div class="form-group">
            <label for="">Pay</label>
            <input type="text" class="form-control">
          </div>
        </div>
        <div class="col-md-4 col-12 mt-8">
            <div class="d-flex ">
                <b-button variant="light">
                    <i class="flaticon2-trash p-0" ></i>
                </b-button>
                <b-button variant="light" class="ml-4">
                    <i class="flaticon2-plus p-0" ></i>
                </b-button>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <label for="">Görüşme Açıklama</label>
                <textarea name="" id=""  rows="3" class="form-control"></textarea>
            </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <b-button variant="light" @click="modalOpen = false">
            Vazgeç
          </b-button>
          <b-button variant="success" @click="modalOpen = false" class="ml-4">
            Kaydet
          </b-button>
        </div>
      </template>
    </b-modal>
  </Card>
</template>
<script>
export default {
  data() {
    return {
      modalOpen: false,
    };
  },
};
</script>
